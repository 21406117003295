import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

const SearchLayoutTitle = ({ searchContainerStyleExcludingCardContainer = {}, lang = "es", place = {name:{"es": "España"}}, numberOfBoats = 50 }) => {
  const [t, i18n] = useTranslation();
  const [numberOfBoatState, setNumberOfBoatState] = useState(numberOfBoats);

  const numberOfBoatsHandler = (e) => {
      e.preventDefault();
      setNumberOfBoatState(e.number);
    };

  useEffect(() => {
      if (typeof document !== 'undefined') {
        document.addEventListener('numberOfBoats', numberOfBoatsHandler, false);
      }
  
      return () => {
        if (typeof document !== 'undefined') {
          document.removeEventListener('numberOfBoats', numberOfBoatsHandler, false);
        }
      };
    }, [numberOfBoatState]);

  return (
    <h1 className="SearchCardContainerTitle" style={searchContainerStyleExcludingCardContainer}>{`${t('Encontrados')} ${numberOfBoatState} ${t('Barcos en')} `}<span style={{textTransform:'capitalize'}}>{place.name[lang]}</span></h1>      
  );
};


export default SearchLayoutTitle;
import { awsConnector } from 'boatjump-react-components';
import React from 'react';
import Header from '../components/Header/Header';
import '../components/layout.css';
import SearchLayout from '../components/SearchLayout/SearchLayout';
import SEO from '../components/seo';
import '../static/search.css';

const Search = ({ pageContext }) => {
  const {
    boats, url, lang, slugPartBoatRent, place, description, special, places, kinds,
  } = pageContext;
  const AWSSearch = awsConnector(SearchLayout, {
    identityPoolId: 'eu-west-1:d89b66a9-ed83-43d4-b4fe-2a836929175c', endopointurl: 'https://api.boatjump.com/v1', endpointname: 'boats', region: 'eu-west-1',
  });
  return (
    <>
          <SEO title="Home" description={description} url={url} />
          <Header page="search" special={special}/>
          <AWSSearch places={JSON.parse(places)} slugPartBoatRent={slugPartBoatRent} place={place} lang={lang} boats={boats} kinds={kinds}/>
    </>
  );
};

export default Search;

import { Rate, Select } from 'antd';
import {
  Input, RangeSelector, withRatingStyle, withSelectStyle,
} from 'boatjump-react-components';
// import RangeSelector from '../RangeSelector/RangeSelector';
import PropTypes from 'prop-types';
import React from 'react';

function defaultReturn() {
}

const ComponentRenderer = (props) => {
  const {
    data, currentValue, value, kinds, lang, places,
  } = props;
  return (
    <ComponentSelector places={places} lang={lang} kinds={kinds} data={data} value={value} currentValue={currentValue} />
  );
};

const ComponentSelector = ({
  data, currentValue, value, kinds, lang, places,
}) => {
  const { type } = data;
  return (type === 'search') ? (
    <div key={value ? value.toString() : 'key'}>
      <Input fieldId={data.id} value={value || []} label={data.label} placeholder={data.placeholder} currentValue={currentValue}>
        {places.map((place) => ({ key: place.slug[lang], value: place.name[lang] }))}
      </Input>
    </div>
  )
    : (type === 'select') ? GenerateSelect(false, data, currentValue, value)
      : (type === 'date') ? <Input fieldId={data.id} value={value} label={data.label} placeholder={data.placeholder} currentValue={currentValue} />
        : (type === 'multiselect') ? (
          <div key={value ? value.toString() : 'key'}>
            <Input fieldId={data.id} value={value || []} currentValue={currentValue} label="Selecciona el tipo de barco" placeholder="Catamarán, Yate, Lancha...">
              {kinds.map((kind) => ({ key: kind.name[lang], value: kind.name[lang] }))}
            </Input>
          </div>
        )
          : (type === 'rate') ? GenerateRate(data, currentValue, value)
            : (type === 'range') ? GenerateRange(data, currentValue, value)
              : (
                <div>
Component
                  {' '}
                  {type}
                  {' '}
not suported
                </div>
              );
};

ComponentRenderer.propTypes = {
  data: PropTypes.object,
  returnValue: PropTypes.func,
};

ComponentRenderer.defaultProps = {
  data: {},
  returnValue: defaultReturn,
};

export default ComponentRenderer;

function GenerateSelect(multiple, data, currentValue) {
  const SelectWithStyle = withSelectStyle(Select, { fieldId: data.id });
  return (
    <div>
      <div className="AdvancedFilterRatingLabel">{data.label}</div>
      {multiple
        ? <SelectWithStyle mode="multiple" fieldId={data.id} />
        : <SelectWithStyle fieldId={data.id} />}
    </div>
  );
}

function GenerateRate(data, currentValue, value) {
  const RatingWithStyle = withRatingStyle(Rate,
    { fieldId: data.id, callbackFunction: currentValue });
  return (
    <div>
      <div className="AdvancedFilterRatingLabel">{data.label}</div>
      <RatingWithStyle value={value} fieldId={data.id} callbackFunction={currentValue} />
    </div>
  );
}
function GenerateRange(data, currentValue, value) {
  return (
    <div key={value}>
      <div className="AdvancedFilterRatingLabel">{data.label}</div>
      <RangeSelector
        defaultValue={value}
        fieldId={data.id}
        minValue={data.properties.min}
        maxValue={data.properties.max}
        currentValue={currentValue}
      />
    </div>
  );
}

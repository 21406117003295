import React, { useEffect, useState } from 'react';
import * as _ from 'lodash';
import CardContainer from '../CardContainer/CardContainer';
import Breadcrumb from '../Breadcrumb/Breadcrumb';
import SearchLayoutTitle from './SearchLayoutTitle';

const SearchLayoutBoats = ({
  api, currentFilters, boats, lang, slugPartBoatRent, place, typeOfBoat, places, kinds,
}) => {
  const initialBoats = Array.from(boats).map((boat) => boat.boat);
  console.log(places);
  const paddingLeftBody = 24;
  const paddingRightBody = 36;
  const cardMargin = 16;
  const cardWidth = 250 + cardMargin;
  const [searchContainerStyle, setSearchContainerStyle] = useState({ maxWidth: '1330px' });
  const [searchContainerWarningStyle, setSearchContainerWarningStyle] = useState({});
  const [searchContainerStyleExcludingCardContainer, setSearchContainerStyleExcludingCardContainer] = useState({});
  const [boatList, setBoatList] = useState([]);
  const [filters, setFilters] = useState(currentFilters);
  const [windowWidth, setWindowWidth] = useState(0);
  const [windowHeight, setWindowHeight] = useState(0);
  const breadcrumbArray = [{ url: '/', text: 'Home' }, { url: `/${lang}/${slugPartBoatRent[lang]}/${place.slug[lang]}`, text: place.name[lang] }];

  if (typeOfBoat) { breadcrumbArray.push({ url: '/', text: typeOfBoat }); }

  const prepareIfArray = (element) => ((Array.isArray(element)) ? element.join(',') : element);

  const fieldsCorrelation = (filtersObject, field) => {
    const fields = {
      departure: `check_in_day=${prepareIfArray(filtersObject[field])}`,
      destination: `searcheable_places=${prepareIfArray(filtersObject[field])}`,
      // duration: , TODO waiting for a way to pass a date and a duration
      kind: `kind_id=${prepareIfArray(filtersObject[field])}`,
      // places: `${}`, TODO waiting for a way to call a between in api
      rate: `average_rating=${filtersObject[field]}`,
      // rate2: `${}`, TODO wating for a way to get owner rating
    };
    return fields[field];
  };


  const queryStringParams = () => {
    if (filters === {}) { return ''; }

    const newFilters = { ...filters };
    Object.keys(newFilters).forEach((el) => { if (!newFilters[el] || newFilters[el].length === 0) { delete newFilters[el]; } });
    if (newFilters.kind) {
      newFilters.kind = kinds.filter((kind) => newFilters.kind.includes(kind.name[lang])).map((kind) => kind.id);
    }

    if (newFilters.destination) {
      newFilters.destination = places.filter((pl) => newFilters.destination.includes(pl.slug[lang])).map((pl) => pl.id);
    }
    const keys = Object.keys(newFilters);
    const newFilter = keys.map((key) => fieldsCorrelation(newFilters, key));

    return (newFilter.length > 0) ? `&${newFilter.join('&')}` : newFilter.join('&');
    // let queryStringParamsString = '&';
    // queryStringParamsString += filters.departure !== '' && filters.departure ? `check_in_day=${filters.departure}&` : '';
    // queryStringParamsString += filters.destination !== "" && filters.destination? `main_place_id=${filters.destination}&` : ""; TODO this field has to be autocompleted and it needs the id to be passed as a field
    // queryStringParamsString += filters.duration !== "" && filters.duration? `check_in_day = ${filters.duration}&` : ""; TODO
    // queryStringParamsString += filters.kind !== '' && filters.kind ? `kind_id=${filters.kind}&` : '';
    // queryStringParamsString += filters.places !== '' && filters.places ? `capacity=${filters.places[0]},${filters.places[1]}&` : '';
    // queryStringParamsString += filters.rate !== '' && (filters.rate || filters.rate === 0) ? `average_rating=${filters.rate}&` : '';
    // queryStringParamsString += filters.rate2 !== "" && (filters.rate2 || filters.rate2 === 0)? `check_in_day=${filters.rate2}` : ""; TODO this is not from boats table
    // console.log(queryStringParamsString.replace(/&{1}$/, '').replace(/&{1}$/, ''));
    // return queryStringParamsString.replace(/&{1}$/, '').replace(/&{1}$/, '');
  };

  const getBoats = () => {
    const apiUrlForGettingFilteredBoats = `/boats?%40limit=50${queryStringParams()}`;
    console.log(apiUrlForGettingFilteredBoats);
    api.get('boats', apiUrlForGettingFilteredBoats, {}).then((response) => {
      setBoatList(response);
    }).catch((error) => {
      setBoatList([]);
    });
  };

  const adaptTemplate = () => {
    if (typeof window !== 'undefined' && typeof document !== 'undefined' && document.getElementsByClassName('SearchAdvancedFilterContainer').length > 0) {
      const advancedFilter = document.getElementsByClassName('SearchAdvancedFilterContainer')[0];
      const advancedFilterWidth = Number((getComputedStyle(advancedFilter).marginRight).split('px')[0]) + advancedFilter.offsetWidth;
      const searchContainerWidth = (window.document.body.offsetWidth - advancedFilterWidth - paddingRightBody - paddingLeftBody) / cardWidth;
      setSearchContainerStyle({ width: `${(Math.trunc(searchContainerWidth) * cardWidth)}px`, maxWidth: '1330px' });
      setSearchContainerStyleExcludingCardContainer({ width: `${Math.trunc(searchContainerWidth) * cardWidth - cardMargin}px`, maxWidth: '1314px' });
    }
  };

  useEffect(() => {
    setWindowWidth(window.innerWidth);
    setWindowHeight(window.innerHeight);
    if (queryStringParams() === '' && boatList.toString() !== initialBoats.toString()) {
      setBoatList(initialBoats);
    } else {
      getBoats();
    }

    if (typeof window !== 'undefined') {
      window.onresize = (e) => {
        adaptTemplate();
      };
      window.addEventListener('orientationchange', () => {
        adaptTemplate();
      }, false);
    }

    adaptTemplate();
  }, []);

  const filterHandler = (e) => {
    e.preventDefault();
    setFilters(e.detail);
    getBoats();
  };
  useEffect(() => {
    if (typeof document !== 'undefined') {
      document.addEventListener('filter', filterHandler, false);
    }

    return () => {
      if (typeof document !== 'undefined') {
        document.removeEventListener('filter', filterHandler, false);
      }
    };
  }, [filters]);

  const closeSearchWarning = () => {
    setSearchContainerWarningStyle({ display: 'none' });
  };

  const MobileSearchLayoutBoats = () => (
    <div>
      <div className="SearchContainer">
        <div className="SearchWarning" style={searchContainerWarningStyle} onClick={closeSearchWarning}>
          <div style={{ display: 'flex', alignItems: (typeof window !== 'undefined' && window.screen.width === 768 && window.screen.height > 500) ? 'center' : 'end' }}>
            <div className="SearchWarningIcon" />
            <div className="SearchWarningText">Un 68% de los barcos de esta ubicación han sido reservados en estas fechas</div>
          </div>
          <div className="SearchCloseIcon" />
        </div>
        <SearchLayoutTitle place={place} lang={lang} numberOfBoats={boatList.length} />
        <CardContainer lang={lang} places={places} slugPartBoatRent={slugPartBoatRent} lang={lang} justifyContentClass="CardContainerContainerJustifyContentClass" cards={boatList} />
      </div>
      <Breadcrumb breadcrumb={breadcrumbArray} style={{ margin: '-56px 0px 56px 24px' }} />
    </div>
  );


  const DesktopSearchLayoutBoats = () => (
    <div style={searchContainerStyle}>
      <div className="SearchWarning" style={({ ...searchContainerWarningStyle, ...searchContainerStyleExcludingCardContainer })} onClick={closeSearchWarning}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <div className="SearchWarningIcon" />
          <div className="SearchWarningText">Un 68% de los barcos de esta ubicación han sido reservados en estas fechas</div>
        </div>
        <div className="SearchCloseIcon" />
      </div>
      <Breadcrumb breadcrumb={breadcrumbArray} />
      <SearchLayoutTitle searchContainerStyleExcludingCardContainer={searchContainerStyleExcludingCardContainer} place={place} lang={lang} numberOfBoats={boatList.length} />
      <CardContainer lang={lang} places={places} slugPartBoatRent={slugPartBoatRent} lang={lang} cards={boatList} />
    </div>
  );

  return (
    <>
      {
        (typeof window !== 'undefined' && window.screen.width > 768 && window.screen.height > 500) ? <DesktopSearchLayoutBoats /> : <MobileSearchLayoutBoats />
      }
    </>
  );
};


export default SearchLayoutBoats;

import { Button } from 'antd';
import { withButtonStyle } from 'boatjump-react-components';
import React, { useEffect, useRef, useState } from 'react';
import moment from 'moment';
import AdvancedFilter from '../AdvancedFilter/AdvancedFilter';
import Header from '../Header/Header';
import SearchLayoutBoats from './SearchLayoutBoats';
import 'moment/locale/es';

const queryString = require('query-string');

const ButtonWithStyle = withButtonStyle(Button);

const SearchLayout = ({
  api, boats, lang, slugPartBoatRent, place, places, kinds,
}) => {
  const searchLocation = typeof window !== 'undefined' ? window.location.search : {};
  const queryParameters = { ...queryString.parse(searchLocation, { parseNumbers: true, parseDates: true }) };
  queryParameters.place = place.name[lang];
  queryParameters.duration = typeof queryParameters.duration === 'number' ? queryParameters.duration : 7;
  queryParameters.start_date = (moment(queryParameters.start_date).locale('es').isValid()
    && moment(queryParameters.start_date).locale('es') > moment().locale('es'))
    ? queryParameters.start_date : moment().locale('es').format('L');

  const advancedFilterMobile = useRef(null);
  const [variableForChanginState, setVariableForChanginState] = useState(0);
  const [filters, setFilters] = useState({});
  const [windowWidth, setWindowWidth] = useState(0);
  const [windowHeight, setWindowHeight] = useState(0);
  let temporalFilters = filters;

  const updateState = () => {
    setVariableForChanginState(variableForChanginState + 1);
  };

  const getFilteredBoats = (params) => {
    temporalFilters = params;
    if (typeof document !== 'undefined' && typeof window !== 'undefined' && (window.screen.width > 768 && window.screen.height > 500)) {
      setFilters(params);
      const event = new CustomEvent('filter', { detail: params });
      document.dispatchEvent(event);
    }
  };

  const closeMobileFilter = () => {
    const event = new CustomEvent('filter', { detail: temporalFilters });
    if (typeof document !== 'undefined') {
      document.dispatchEvent(event);
    }
    // //console.log(advancedFilterMobile.current, 'ADVANCED FILTER');
    try {
      advancedFilterMobile.current.style.animation = '1s slideout';
      setTimeout(() => { advancedFilterMobile.current.style.display = 'none'; }, 900);
    } catch (e) {
      // //console.log('Error thrown because of trying to get filter ref on mobile on desktop');
    }
  };

  const openMobileFilter = () => {
    try {
      if (advancedFilterMobile.current) {
        advancedFilterMobile.current.style.animation = '1s slidein';
        advancedFilterMobile.current.style.display = 'flex';
      }
    } catch (e) {
      // //console.log('Error thrown because of trying to get filter ref on mobile on desktop');
    }
  };

  const onResizeOrOrientationChange = () => {
    try {
      if (typeof window !== 'undefined' && advancedFilterMobile.current && (window.screen.width > 768 && window.screen.height > 500)) {
        advancedFilterMobile.current.style.animation = 'none';
        advancedFilterMobile.current.style.display = 'flex';
      }
    } catch (e) {
      // //console.log('Error thrown because of trying to get filter ref on mobile on desktop');
    }
    setFilters(temporalFilters);
    updateState();
  };

  useEffect(() => {
    temporalFilters = filters;

    if (typeof window !== 'undefined') {
      window.onorientationchange = (e) => {
        onResizeOrOrientationChange();
      };
      window.onresize = (e) => {
        onResizeOrOrientationChange();
      };
      setWindowWidth(window.innerWidth);
      setWindowHeight(window.innerHeight);
    }
  }, [variableForChanginState]);

  const MobileLayout = () => (
    <>
      <SearchLayoutBoats
        kinds={kinds}
        places={places}
        typeOfBoat={queryParameters.boat}
        place={place}
        slugPartBoatRent={slugPartBoatRent}
        lang={lang}
        boats={boats}
        api={api}
        currentFilters={filters}
      />
      <div style={{
        position: 'fixed', bottom: '0px', display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%', height: '56px', backgroundColor: 'white', borderTop: '1px solid rgba(0, 0, 0, 0.35)',
      }}
      >
        <div style={{ width: '160px', height: '36px' }}>
          <ButtonWithStyle onClick={openMobileFilter}>Filtros</ButtonWithStyle>
        </div>
      </div>
      <div ref={advancedFilterMobile} className="SearchAdvancedFilterContainer" style={{ display: 'none' }}>
        <Header onClick={closeMobileFilter} page="searchMobile" />
        <AdvancedFilter
          places={places}
          lang={lang}
          kinds={kinds}
          currentValues={filters}
          myreturn={getFilteredBoats}
        />
      </div>
    </>
  );


  const DesktopLayout = () => (
    <div className="SearchContainer">
      <div className="SearchAdvancedFilterContainer">
        <AdvancedFilter
          places={places}
          lang={lang}
          kinds={kinds}
          currentValues={filters}
          myreturn={getFilteredBoats}
          style={{ display: 'flex' }}
        />
      </div>
      <SearchLayoutBoats
        kinds={kinds}
        places={places}
        typeOfBoat={queryParameters.boat}
        place={place}
        slugPartBoatRent={slugPartBoatRent}
        lang={lang}
        boats={boats}
        api={api}
        currentFilters={filters}
      />
    </div>
  );

  return (
    <>
      {
        (typeof window !== 'undefined' && window.screen.width > 768 && window.screen.height > 500) ? <DesktopLayout /> : <MobileLayout />
      }
    </>
  );
};


export default SearchLayout;

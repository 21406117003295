const schema = [
    {
        "id": "destination",
        "type": "search",
        "label": "Destino",
        "placeholder": "Destino",
        "description": "Todo viaje....blabla",
        "properties":{
            "service":"service"
        }
    },
    {
        "id": "duration",
        "type": "select",
        "label": "Duración",
        "placeholder": "Duración",
        "properties":{
            "values":[
                {
                    "value":"2w",
                    "text":{
                        "es": "2 semanas",
                        "en": "2 weeks",
                        "de": "2 Woche"
                    }
                },
                {
                    "value":"1w",
                    "text":{
                        "es": "1 semana",
                        "en": "1 week",
                        "de": "1 Woche"
                    }
            }
                
            ]
        }
    },
    {
        "id": "departure",
        "type": "date",
        "label":"Salida",
        "placeholder": "Salida"
    },
    {
        "id": "kind",
        "type": "multiselect",
        "label": "Selecciona el tipo de barco",
        "placeholder": "Duración",
        "properties":{
            "values":[]
        }
    },
    {
        "id": "rate",
        "type": "rate",
        "label": "Valoración del barco"
    },
    {
        "id": "rate2",
        "type": "rate",
        "label": "Valoración del propietario"
    },
    {
        "id": "places",
        "type": "range",
        "label": "Plazas",
        "properties":{
            "min":0,
            "max":24   
        }
    }
]
export default schema;
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import ComponentRenderer from '../ComponentRenderer/ComponentRenderer';
import './AdvancedFilter.css';
import schema from './schema.js';

const AdvancedFilter = (props) => {
  const {
    smartFilter, currentValues, kinds, lang, places,
  } = props;
  const [formData, setFormData] = useState(schema);
  const [formValues, setFormValues] = useState({});

  const valueHandler = (e, value) => {
    console.log(e, value);
    const tmpValue = {};
    tmpValue[e] = value;
    const newValues = { ...formValues, ...tmpValue };
    console.log(newValues, 'NEW VALUES');
    setFormValues(newValues);
    props.myreturn(newValues);
  };

  useEffect(() => {
    if (currentValues) {
      setFormValues(currentValues);
    } else {
      const tmpObj = {};
      formData.map((data) => {
        tmpObj[data.id] = '';
      });
      setFormValues(tmpObj);
    }
    console.log(formValues, 'FORMVALUES');
  }, []);

  return (
    <div className="AdvancedFilter">
      <div style={{ padding: '10px' }}>
        {
          formData.map((component) => (
            <div style={{ marginBottom: '16px' }} key={component.label}>
              <ComponentRenderer
                data={component}
                value={formValues[component.id]}
                returnValue={valueHandler}
                currentValue={valueHandler}
                kinds={kinds}
                lang={lang}
                places={places}
              />
            </div>
          ))
            }
      </div>
    </div>
  );
};

AdvancedFilter.propTypes = {
  smartFilter: PropTypes.shape({
    title: PropTypes.string,
    destinations: PropTypes.array,
  }),

};
AdvancedFilter.defaultProps = {
  smartFilter: {
    title: '¿Dónde te gustaría navegar?',
    destinations: [
      'Islas Baleares',
      'Península',
      'Islas Griegas',
      'Croacia',
      'Turquía',
      'Caribe',
    ],
  },
};

export default AdvancedFilter;
